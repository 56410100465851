.authMain {
    height: 100vh;
    @apply inline-block w-full tablet:flex tablet:justify-center tablet:bg-common-white;
}
.authContainer {
    @apply flex flex-col items-center justify-center w-full h-full ml-0 mr-auto;
}
.authLeft {
    max-width: 480px;
    width: 480px;
    vertical-align: top;
    @apply inline-block h-full bg-common-white desktop:w-96 ipad:w-full ipad:max-w-full;
}
/* .authImage{
    @apply w-full h-full;
} */
.authRight {
    width: calc(100% - 480px);
    @apply -mb-2 inline-block h-full bg-cover desktop:w-[calc(100%-384px)] ipad:hidden;
}
.authContainer .card {
    @apply w-full border-0 bg-common-white;
}
.authContainer .card .cardBody {
    @apply px-16 border-0 desktop:px-10 tablet:px-0 mobile:px-10;
}
.authContainer .cardBody .logo {
    @apply absolute top-16  left-16 w-[80px]  lg:top-5 desktop:left-10 tablet:left-36 mobile:left-[44px];
}
.authContainer .cardBody .textDescription {
    @apply mb-8 text-interface-700;
}
.authContainer .cardBody .linkDescription {
    @apply mb-4 mr-2 font-medium;
}
.authContainer .authFooter {
    @apply absolute block text-sm text-center bottom-4 text-interface-600 desktop:px-10;
}
