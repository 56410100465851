.btn {
    @apply relative flex h-[46px] flex-shrink-0 items-center justify-center overflow-hidden  rounded-md border border-gray-300 text-center font-acumin-bold text-base text-interface-700  outline-0 focus:ring-0;
}
.btn.sm {
    @apply h-[33px] rounded-md pb-[1px] text-sm;
}
.btn.md {
    @apply h-12 text-lg;
}
.btn.base {
    @apply h-9 text-sm;
}
.btn.other {
    @apply h-[41px];
}
.btn.xs {
    @apply h-[41px] px-3.5 text-sm;
}
.primary {
    @apply border-transparent bg-primary-500 text-common-white shadow-md transition-all hover:bg-primary-700 !important;
}
.secondary {
    @apply h-10 bg-common-white text-gray-700 hover:border-gray-400 !important;
}
.success {
    @apply bg-success-600 text-common-white hover:bg-success-700 !important;
}
.cancelled {
    @apply bg-common-black text-common-white hover:bg-common-black !important;
}
.gray {
    @apply pointer-events-none bg-interface-400 text-common-white hover:bg-interface-400 !important;
}
.outline {
    @apply border-primary-500 bg-primary-50 text-primary-500 !important;
}
.outline-interface {
    @apply border-interface-200 bg-transparent text-interface-600 shadow-sm !important;
}
.dark {
    @apply border-interface-500 bg-interface-600 text-interface-200 !important;
}
.link {
    @apply h-auto border-0 py-0.5 text-primary-500 !important;
}
.btn.link-xs span {
    @apply p-0 font-themeFont text-sm text-primary-500 underline;
}
.btn .labelText {
    @apply font-acumin-bold;
}
.btn .text-justify-start {
    @apply justify-start;
}
.btn i {
    @apply mr-2 text-base;
}
.link i {
    @apply text-xs;
}
.btn .iconPosition.right {
    @apply flex-row-reverse;
}
.btn .iconPosition.right i {
    @apply ml-3 mr-0;
}
.btn .spinner {
    @apply absolute left-0 right-0 m-auto w-6 opacity-0;
}
.btn.secondary.loading,
.btn.secondary[loading] {
    @apply pointer-events-none border-interface-200 bg-interface-300 text-transparent;
}
.btn.loading,
.btn[loading] {
    @apply pointer-events-none border-transparent bg-interface-100 text-transparent;
}
.btn.loading .spinner {
    @apply opacity-100;
}
.btn.loading .labelText {
    @apply opacity-0;
}
.btn.disabled,
.btn[disabled] {
    @apply cursor-not-allowed opacity-70;
}
.underline-text-only.text-sm {
    @apply font-acumin-bold;
}
.waitingquote {
    @apply border-0 bg-status-newquoterequest text-common-white hover:bg-status-newquoterequest !important;
}
.readytobook {
    @apply border-0 bg-status-contractsent text-common-white hover:bg-status-contractsent !important;
}
.quotesavaliable {
    @apply border-0 bg-status-quotesent text-common-white hover:bg-status-quotesent !important;
}
.waitingforcontract {
    @apply border-0 bg-status-quoteapproved text-common-white hover:bg-status-quoteapproved !important;
}
