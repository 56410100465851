.alert {
    @apply mb-5 flex min-h-[42px] rounded-md border border-success-500 bg-success-100 p-3  transition-opacity duration-100;
}
.alert.sm {
    @apply min-h-[36px] p-2 px-3;
}
.alert.success {
    @apply border-success-500 bg-success-100;
}
.alert.success,
.alert.danger i {
    @apply text-sm;
}
.alert.danger {
    @apply border-danger-600 bg-danger-100;
}
