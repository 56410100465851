@font-face {
    font-family: 'acumin';
    src: url('/fonts/AcuminProSemiCond-Regular.eot');
    src: url('/fonts/AcuminProSemiCond-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('/fonts/AcuminProSemiCond-Regular.woff2') format('woff2'),
        url('/fonts/AcuminProSemiCond-Regular.woff') format('woff'),
        url('/fonts/AcuminProSemiCond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'acumin-bold';
    src: url('/fonts/AcuminProSemiCond-Semibold.eot');
    src: url('/fonts/AcuminProSemiCond-Semibold.eot?#iefix')
            format('embedded-opentype'),
        url('/fonts/AcuminProSemiCond-Semibold.woff2') format('woff2'),
        url('/fonts/AcuminProSemiCond-Semibold.woff') format('woff'),
        url('/fonts/AcuminProSemiCond-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
