.logsBlock {
    @apply fixed left-[261px] z-50  h-screen w-[390px] bg-common-white p-4 px-0 shadow ipad:left-0 ipad:z-[99] mobile:w-full;
}
.activityBlock {
    @apply top-0 right-0 left-auto;
}
.title {
    @apply mb-4 ml-4;
}
.items {
    @apply flex-col;
}
.item {
    @apply relative flex last-of-type:mb-0 last-of-type:border-none last-of-type:pb-0;
}
.btnClose {
    @apply absolute top-0 right-6 z-[99] hidden cursor-pointer rounded-full bg-interface-200 text-center shadow-md;
}
.notificationList {
    @apply relative mt-3 flex w-full flex-row border-b border-dashed border-interface-200 px-4 pb-3 pr-6;
}
.activityBlock .notificationList {
    @apply pr-4;
}
li.item:hover .btnClose {
    @apply block;
}
.image {
    @apply mr-3 h-6 w-6 flex-shrink-0;
}
.description {
    @apply mb-0 flex w-full;
}
.item:last-child .description {
    @apply mb-0 border-none pb-0;
}
.time {
    @apply ml-auto mr-0 flex-shrink-0 pl-2 text-interface-600;
}
li.item:hover .time {
    @apply opacity-0;
}
.activityBlock li.item:hover .time {
    @apply opacity-100;
}
.backDrop {
    @apply pointer-events-none fixed  top-0 right-0  z-30 h-full w-[calc(100%-270px)] bg-common-black opacity-60;
}
.activityBackDrop {
    @apply left-0 right-auto;
}
