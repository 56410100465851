.sidebar {
    @apply fixed inline-block h-screen w-[260px] mobile:w-full ipad:w-[320px] border-r border-gray-200 bg-interface-100;
}
.sidebarLogo {
    @apply relative flex h-[82px] items-center border-b border-dashed border-gray-200 px-4 py-3;
}
.sidebarLogo .icons {
    @apply absolute flex gap-4 right-4 top-4 ipad:right-[54px];
}
.sidebarLogo .notification {
    @apply px-2 py-2 rounded-full right-2 bg-common-white;
}
.sidebar li i {
    @apply absolute top-[6px] left-3 font-themeFont text-xl;
}
.sidebar li.active i {
    @apply text-common-white;
}
.sidebarLogo .notification .notificationIcon {
    @apply relative flex items-center justify-center w-4 h-4 text-2xl text-center cursor-pointer text-common-black;
}
.sidebarLogo .notification .notificationIcon i {
    @apply text-xl;
}
.sidebarLogo .notification .bellIcon i {
    @apply text-2xl;
}
.notification .notificationIcon .count {
    @apply absolute top-0 right-[-7px] flex h-[14px] w-[14px] items-center justify-center rounded-full bg-primary-500 text-[10px] text-common-white;
}
.userProfile {
    @apply relative flex h-[76px] items-center border-b border-dashed border-gray-200 px-4 py-3;
}
.userProfile .avatar {
    @apply flex h-[38px] w-[38px] items-center justify-center overflow-hidden rounded-full bg-interface-400 text-base text-common-white;
}
.userProfile .userInfo {
    @apply ml-4;
}
.userProfile .userInfo p {
    @apply mb-0 text-base text-common-black;
}
.userProfile .userInfo span {
    @apply text-sm text-gray-500;
}
.sidebarNavigation {
    height: calc(100% - 160px);
    @apply p-4 overflow-auto;
}
.sidebar ul {
    @apply list-none bg-transparent;
}
.sidebar li {
    @apply relative mb-3 h-[40px] w-full cursor-pointer list-none py-2 pl-11 font-themeFont text-base text-common-black;
}
.sidebar li a {
    @apply text-common-black;
}
.sidebar li.inActive {
    @apply bg-transparent text-common-black;
}
.sidebar li.active {
    @apply rounded-md bg-primary-500 font-acumin-bold text-common-white;
}
.sidebar li.active a {
    @apply font-acumin-bold text-common-white;
}
