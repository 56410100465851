@font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot?nt9z6z');
    src: url('/fonts/icomoon.eot?nt9z6z#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?nt9z6z') format('truetype'),
        url('/fonts/icomoon.woff?nt9z6z') format('woff'),
        url('/fonts/icomoon.svg?nt9z6z#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-copy:before {
    content: '\e937';
}
.icon-list:before {
    content: '\e936';
}
.icon-users-remove:before {
    content: '\e938';
}
.icon-lock:before {
    content: '\e900';
}
.icon-unsubscribe:before {
    content: '\e901';
}
.icon-add-new:before {
    content: '\e902';
}
.icon-users:before {
    content: '\e903';
}
.icon-info:before {
    content: '\ea0c';
}
.icon-notification:before {
    content: '\ea08';
}
.icon-warning:before {
    content: '\ea07';
}
.icon-table:before {
    content: '\e92d';
}
.icon-pipeline:before {
    content: '\e92e';
}
.icon-globe:before {
    content: '\e92f';
}
.icon-card-stack-plus:before {
    content: '\e92c';
}
.icon-phone:before {
    content: '\e92a';
}
.icon-archive:before {
    content: '\e929';
}
.icon-list2:before {
    content: '\e9bb';
}
.icon-fill-check:before {
    content: '\e904';
}
.icon-sort:before {
    content: '\e931';
}
.icon-partial:before {
    content: '\e905';
}
.icon-badge-check:before {
    content: '\e906';
}
.icon-close:before {
    content: '\e91f';
}
.icon-menu1:before {
    content: '\e951';
}
.icon-chat-bubble:before {
    content: '\e928';
}
.icon-multileg:before {
    content: '\e907';
}
.icon-roundtrip:before {
    content: '\e908';
}
.icon-oneway:before {
    content: '\e909';
}
.icon-search:before {
    content: '\e90a';
}
.icon-caret-down:before {
    content: '\e90b';
}
.icon-caret-right:before {
    content: '\e90c';
}
.icon-arrow-left:before {
    content: '\e90d';
}
.icon-check:before {
    content: '\e90e';
}
.icon-credit-card:before {
    content: '\e90f';
}
.icon-clip:before {
    content: '\e910';
}
.icon-airplane:before {
    content: '\e922';
}
.icon-airplane-left:before {
    content: '\e911';
}
.icon-airplane-right:before {
    content: '\e912';
}
.icon-archive1:before {
    content: '\e913';
}
.icon-avatar:before {
    content: '\e914';
}
.icon-check-circle:before {
    content: '\e915';
}
.icon-clock:before {
    content: '\e916';
}
.icon-payments:before {
    content: '\e919';
}
.icon-plus-circle:before {
    content: '\e917';
}
.icon-edit:before {
    content: '\e918';
}
.icon-envelope-closed:before {
    content: '\e91a';
}
.icon-home:before {
    content: '\e91b';
}
.icon-image:before {
    content: '\e91c';
}
.icon-mobile:before {
    content: '\e91d';
}
.icon-paper-plane-right:before {
    content: '\e91e';
}
.icon-plus:before {
    content: '\e920';
}
.icon-reader:before {
    content: '\e921';
}
.icon-trash:before {
    content: '\e923';
}
.icon-configurations:before {
    content: '\e924';
}
.icon-pie-chart:before {
    content: '\e925';
}
.icon-aircrafts:before {
    content: '\e926';
}
.icon-calendar:before {
    content: '\e927';
}
.icon-backpack:before {
    content: '\e92b';
}
.icon-clients:before {
    content: '\e930';
}
.icon-file-text:before {
    content: '\e932';
}
.icon-notification-bell:before {
    content: '\e933';
}
.icon-signout:before {
    content: '\e934';
}
.icon-stack:before {
    content: '\e935';
}
