.flightComponent {
    @apply relative flex w-full mobile:block;
}
.sidebarComponent.flightComponent {
    @apply mobile:flex;
}
.departureCol {
    @apply ml-auto flex w-full flex-col text-left md:mr-[40px];
}
.sidebarComponent .departureCol {
    @apply md:mr-0;
}
.destinationCol {
    @apply flex w-full flex-col text-right mobile:mt-[80px] mobile:pl-0 mobile:text-left;
}
.sidebarComponent .destinationCol {
    @apply mobile:mt-0 mobile:text-right;
}
.originFBOName,
.destinationFBOName {
    @apply relative mb-0 max-w-[200px] font-acumin-bold text-base;
}
.destinationFBOName {
    @apply ml-auto mobile:ml-0;
}
.originCity,
.destinationCity {
    @apply relative mb-1 max-w-[200px] text-xs;
}
.destinationCity {
    @apply ml-auto mobile:ml-0;
}
.dayDate {
    @apply font-themeFont;
}
.time {
    @apply inline-block font-acumin-bold;
}
.colRows {
    @apply flex flex-col gap-1;
}
.plane {
    @apply text-3xl text-interface-400 mobile:mr-2 mobile:text-[18px];
}
.vertical-seprator:after {
    @apply absolute h-full w-[1px] bg-interface-200;
}
.flight-lg {
    @apply mt-0;
}
.sm {
    @apply border-none px-3;
}
.sm .colRows {
    @apply flex-row mobile:block;
}
.sm .colRows.colRowsLeft {
    @apply justify-end;
}
.sm .originCity {
    @apply mb-0 font-acumin-bold text-base text-common-black;
}
.sm .dayDate {
    @apply mb-0 font-themeFont text-common-black;
}
.sm .plane {
    @apply mb-0 text-interface-400;
}
.sm .originFBOName {
    @apply mb-2 text-common-black;
}
.sm .destinationFBOName {
    @apply mb-2 text-common-black;
}
.sm .noOfPassengers {
    @apply text-common-black;
}
.sm .destinationCol {
    @apply mobile:mt-0;
}
.separater {
    @apply absolute left-0 right-0 bottom-[25px] mx-auto h-[1px] w-full  border-[0.5px] border-dashed border-interface-200;
}
/* Sidepanel component */
.flightComponent.sidebarComponent {
    @apply relative;
}
.flightComponent.sidebarComponent .departureCol,
.flightComponent.sidebarComponent .destinationCol {
    @apply w-2/5 flex-shrink-0 mobile:w-2/6;
}
.flightComponent.sidebarComponent .originCity,
.flightComponent.sidebarComponent .destinationCity {
    @apply mb-4;
}
.flightComponent.sidebarComponent .seperator {
    @apply bottom-2;
}
/* workspace component */
.flightComponent.booking {
    @apply mb-4 border-b border-dashed pb-4;
}
.flightComponent.booking .originFBOName,
.flightComponent.booking .destinationFBOName {
    @apply font-acumin-bold text-2xl;
}
.flightComponent.priceQuote .originFBOName,
.flightComponent.priceQuote .destinationFBOName {
    @apply font-acumin-bold text-base;
}
.flightComponent.priceQuote .noOfPassengers,
.flightComponent.priceQuote .originCity,
.flightComponent.priceQuote .destinationCity,
.flightComponent.priceQuote .time,
.flightComponent.priceQuote .dayDate {
    @apply mb-0 inline-block text-sm ipad:block mobile:z-[1] mobile:inline-block;
}
.flightComponent.priceQuote .plane {
    @apply text-2xl;
}
.flightComponent.booking .noOfPassengers,
.flightComponent.booking .originCity,
.flightComponent.booking .destinationCity,
.flightComponent.booking .time,
.flightComponent.booking .dayDate {
    @apply mb-0 inline-block text-sm ipad:block mobile:z-[1] mobile:inline-block;
}
.flightComponent.booking .noOfPassengers {
    @apply w-[100px] cursor-pointer font-acumin-bold;
}
/* Iternary */
.flightComponent.booking.itinerary {
    @apply mb-0 border-none pb-0;
}
.overlayAction {
    @apply absolute top-0 left-0 right-0 h-full w-full cursor-pointer;
}
.addLeg,
.removeLeg {
    @apply relative z-0 flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-full border border-interface-300 bg-interface-100 p-2 text-center text-interface-600 shadow-sm hover:w-full;
}
.itineraryFirstItemClass:first-child .removeLeg {
    @apply top-6;
}
.addLeg span,
.removeLeg span {
    @apply relative ml-2 hidden;
}
.addSubscribe span {
    @apply relative ml-2 hidden;
}
.addLeg:hover span,
.removeLeg:hover span {
    @apply inline-block w-full;
}
.addSubscribe {
    @apply relative mb-0 flex h-[24px] w-[22px] cursor-pointer items-center justify-center text-center text-interface-600 hover:w-full;
}
.addSubscribe:hover {
    @apply rounded-full border border-interface-300 bg-interface-200 p-2;
}
.addSubscribe:hover span {
    @apply inline-block w-full;
}
